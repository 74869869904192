export default {
  methods: {
    trackIBATLink (label=null, mouseButton='left') {
      const intent = mouseButton === 'left' ? 'Definite' : 'Intent'

      if (this.$ga) {
        this.$ga.event('Link', 'IBAT', label, intent)
      }
    }
  }
}